import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as EEnterpriseLogo } from "./assets/logos/e&enterprise.svg";

import GetStarted from "./screens/GetStarted";
import SelectOptions from "./screens/SelectOptions";
import StartScan from "./screens/StartScan";
import Scan from "./screens/Scan";
import Result from "./screens/Result";
import DownloadReport from "./screens/DownloadReport";
import Contact from "./screens/Contact";

const Header = () => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  return (
    <main className="min-h-screen flex flex-col relative bg-[#fcfcfc]">
      {pathname !== "/" && (
        <EEnterpriseLogo className="self-center shrink-0 my-10 mx-8 w-2/5 max-w-[350px] text-primary" />
      )}
      <Outlet />
    </main>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Header />,
    children: [
      { index: true, element: <GetStarted /> },
      { path: "/select-options", element: <SelectOptions /> },
      { path: "/start-scan", element: <StartScan /> },
      { path: "/scan", element: <Scan /> },
      { path: "/result", element: <Result /> },
      { path: "/report", element: <DownloadReport /> },
    ],
  },
  { path: "/contact", element: <Contact /> },
]);

const App = () => <RouterProvider router={router} />;

export default App;
